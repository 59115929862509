import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./Destiny.css";
import chaldeanChartImage from "../assets/Chaldean-1.png";
import { destinyInstructions } from "./Destiny_How_To";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  DESTINY_FAQ_QUESTION_1,
  DESTINY_FAQ_ANSWER_1,
  DESTINY_FAQ_QUESTION_2,
  DESTINY_FAQ_ANSWER_2,
  DESTINY_FAQ_QUESTION_3,
  DESTINY_FAQ_ANSWER_3,
  DESTINY_FAQ_QUESTION_4,
  DESTINY_FAQ_ANSWER_4,
  DESTINY_FAQ_QUESTION_5,
  DESTINY_FAQ_ANSWER_5,
  DESTINY_FAQ_QUESTION_6,
  DESTINY_FAQ_ANSWER_6,
  DESTINY_FAQ_QUESTION_7,
  DESTINY_FAQ_ANSWER_7,
  DESTINY_FAQ_QUESTION_8,
  DESTINY_FAQ_ANSWER_8,
  DESTINY_FAQ_QUESTION_9,
  DESTINY_FAQ_ANSWER_9,
  DESTINY_FAQ_QUESTION_10,
  DESTINY_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsDestiny from "./Destiny_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: DESTINY_FAQ_QUESTION_1, answer: DESTINY_FAQ_ANSWER_1 },
    { question: DESTINY_FAQ_QUESTION_2, answer: DESTINY_FAQ_ANSWER_2 },
    { question: DESTINY_FAQ_QUESTION_3, answer: DESTINY_FAQ_ANSWER_3 },
    { question: DESTINY_FAQ_QUESTION_4, answer: DESTINY_FAQ_ANSWER_4 },
    { question: DESTINY_FAQ_QUESTION_5, answer: DESTINY_FAQ_ANSWER_5 },
    { question: DESTINY_FAQ_QUESTION_6, answer: DESTINY_FAQ_ANSWER_6 },
    { question: DESTINY_FAQ_QUESTION_7, answer: DESTINY_FAQ_ANSWER_7 },
    { question: DESTINY_FAQ_QUESTION_8, answer: DESTINY_FAQ_ANSWER_8 },
    { question: DESTINY_FAQ_QUESTION_9, answer: DESTINY_FAQ_ANSWER_9 },
    { question: DESTINY_FAQ_QUESTION_10, answer: DESTINY_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function Destiny({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount, fullName, setFullName } =
    useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60); // Initial timer countdown seconds
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseReadyToShow, setResponseReadyToShow] = useState(false);
  const url = window.location.href;
  const mouseTitle = "Find your Destiny Number";
  const browserTitle = "Destiny Number";
  const shareText =
    "Hi, my Destiny Number says a lot of things about me. Check yours now at";

  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
    setApiResponse(null); // Clear previous API result
  };

  const hasContent = apiResponse !== null;

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    if (!fullName.trim()) {
      setError("Please enter your Full Name first.");
      return;
    }

    setLoading(true);
    const startTime = Date.now(); // Record the start time
    setUserData({
      ...userData,
      fullName: fullName,
    });

    setError(null);

    try {
      const response = await fetch(
        "https://api.scanandplaytambola.com/destinyNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: fullName,
          }),
        }
      );

      const data = await response.json();
      setApiResponse(data);

      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const remainingTime = 5000 - elapsedTime; // Ensure a minimum of 5 seconds

      setTimeout(() => {
        setLoading(false);
        setResponseReadyToShow(true); // Now the response is ready to show
      }, Math.max(remainingTime, 0)); // Adjust timeout to ensure it is not negative
    } catch (error) {
      setError("Failed to fetch the data. Please try again.");
      setLoading(false);
      setResponseReadyToShow(false); // Ensure we do not show any incomplete data
    } finally {
      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 13, 22, 20, 2, 10, 21, 15, 7, 24, 18];

  return (
    <div className="Destiny">
      <Helmet>
        <title>Destiny Number</title>
        <meta
          name="description"
          content="Unveil your Destiny Number to learn about your life’s mission and the path you are meant to follow according to numerology."
        />
      </Helmet>
      <div className="content">
        <br></br>
        <div className="input-and-result-window">
          <div className="special">
            <h1>Know more about your Destiny</h1>
            <div>
              <p className="label">Enter your Full Name:</p>
              <input
                type="text"
                value={fullName}
                onChange={handleFullNameChange}
                placeholder="Type here..."
              />
            </div>
            {error && (
              <p className="error" style={{ color: "red" }}>
                {error}
              </p>
            )}
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {loading && <Loader />}
            <p>
              Destiny Number :{" "}
              <b>{!loading && apiResponse ? apiResponse.destinyNumber : ""}</b>
            </p>
            {!loading && apiResponse && apiResponse.destinyNumber && (
              <p>
                <em>For more details, see below...</em>
              </p>
            )}
            {/* <p>
              <em>For interpretation, see below...</em>
            </p> */}
          </div>
        </div>
        {/* Loader component is displayed when loading is true */}
        {loading && <Loader />}
        <div></div>
        {!loading && responseReadyToShow && apiResponse && (
          <div
            className={`left-align-content result-ui ${
              hasContent ? "filled" : ""
            }`}
            id="breadcrumbs"
          >
            <div>
              <h2 className="h2-center">Interpretation </h2>
              <b className="soul-urge-subheadings">Strengths</b>
              <br></br>
              <br></br>
              {Object.entries(apiResponse.strength).map(
                ([key, value], index) => (
                  <div key={index}>
                    <b>{key}:</b> {value}
                  </div>
                )
              )}
            </div>
            <div>
              <br></br>
              <br></br>
              <b className="soul-urge-subheadings">Challenges</b>
              <br></br>
              {Object.entries(apiResponse.challenges).map(
                ([key, value], index) => (
                  <div key={index}>
                    <b>{key}:</b> {value}
                  </div>
                )
              )}
            </div>
            <div>
              <br></br>
              <b className="soul-urge-subheadings">
                How to Utilize These Traits
              </b>
              <br></br>
              <br></br>
              {Object.entries(apiResponse.how_to_UtilizetheseTraits).map(
                ([key, value], index) => (
                  <div key={index}>
                    <b>{key}:</b> {value}
                  </div>
                )
              )}
            </div>
            <div>
              <br></br>
              <b className="soul-urge-subheadings">How to Fix and Improve</b>
              <br></br>
              <br></br>
              {Object.entries(apiResponse.howtofixandImprove).map(
                ([key, value], index) => (
                  <div key={index}>
                    <b>{key}:</b> {value}
                  </div>
                )
              )}
            </div>
            <div>
              <br></br>
              <b className="soul-urge-subheadings">Destiny Traits</b>
              <br></br>
              <br></br>
              {Object.entries(apiResponse.destinyTraits).map(
                ([key, value], index) => (
                  <div key={index}>
                    <b>{key}:</b> {value}
                  </div>
                )
              )}
            </div>
          </div>
        )}
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>How to Calculate: </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: destinyInstructions.howToCalculate,
            }}
          />
          <img
            className="center-image-chaldean"
            src={chaldeanChartImage}
            alt="Chandelean Chart"
          />
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <a href={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ExampleUI texts={exampleTextsDestiny} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        {/* FAQ Section */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default Destiny;
