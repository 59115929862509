import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./Partner.css";
import { partnerInstructions } from "./Partner_How_To";
import ShareButtons from "../components/ShareButtons";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  PARTNER_FAQ_QUESTION_1,
  PARTNER_FAQ_ANSWER_1,
  PARTNER_FAQ_QUESTION_2,
  PARTNER_FAQ_ANSWER_2,
  PARTNER_FAQ_QUESTION_3,
  PARTNER_FAQ_ANSWER_3,
  PARTNER_FAQ_QUESTION_4,
  PARTNER_FAQ_ANSWER_4,
  PARTNER_FAQ_QUESTION_5,
  PARTNER_FAQ_ANSWER_5,
  PARTNER_FAQ_QUESTION_6,
  PARTNER_FAQ_ANSWER_6,
  PARTNER_FAQ_QUESTION_7,
  PARTNER_FAQ_ANSWER_7,
  PARTNER_FAQ_QUESTION_8,
  PARTNER_FAQ_ANSWER_8,
  PARTNER_FAQ_QUESTION_9,
  PARTNER_FAQ_ANSWER_9,
  PARTNER_FAQ_QUESTION_10,
  PARTNER_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsCompatibility from "./Partner_Example_Text";
import ExampleUI from "../components/ExampleUI";

import Loader from "../components/Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: PARTNER_FAQ_QUESTION_1, answer: PARTNER_FAQ_ANSWER_1 },
    { question: PARTNER_FAQ_QUESTION_2, answer: PARTNER_FAQ_ANSWER_2 },
    { question: PARTNER_FAQ_QUESTION_3, answer: PARTNER_FAQ_ANSWER_3 },
    { question: PARTNER_FAQ_QUESTION_4, answer: PARTNER_FAQ_ANSWER_4 },
    { question: PARTNER_FAQ_QUESTION_5, answer: PARTNER_FAQ_ANSWER_5 },
    { question: PARTNER_FAQ_QUESTION_6, answer: PARTNER_FAQ_ANSWER_6 },
    { question: PARTNER_FAQ_QUESTION_7, answer: PARTNER_FAQ_ANSWER_7 },
    { question: PARTNER_FAQ_QUESTION_8, answer: PARTNER_FAQ_ANSWER_8 },
    { question: PARTNER_FAQ_QUESTION_9, answer: PARTNER_FAQ_ANSWER_9 },
    { question: PARTNER_FAQ_QUESTION_10, answer: PARTNER_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function Partner({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const [, setError] = useState("");
  const url = window.location.href;
  const shareText =
    "It is so easy to find out how compatible I am with my partner with this cool tool. Wondering about your relationship? Check now at";
  const mouseTitle = "How compatible are you with your Partner?";
  const browserTitle = "Partner Compatibility";

  const [userFullName, setUserFullName] = useState(
    (userData && userData.userFullName) || ""
  );
  const [userDOB, setUserDOB] = useState(
    userData && userData.userDOB ? new Date(userData.userDOB) : null
  );
  
  const [partnerFullName, setPartnerFullName] = useState("");
  const [partnerDOB, setPartnerDOB] = useState(null);
  const [compatibilityData, setCompatibilityData] = useState(null); // State to store API response
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status
  const [loading] = useState(false);
  const [errors, setErrors] = useState({
    userFullName: "",
    userDOB: "",
    partnerFullName: "",
    partnerDOB: "",
  });
  const hasContent = compatibilityData !== null;

  const handleUserFullNameChange = (event) => {
    setUserFullName(event.target.value);
    setErrors({ ...errors, userFullName: "" }); // Clear error message
    setCompatibilityData(null); // Clear previous API result
  };

  const handleUserDOBChange = (date) => {
    setUserDOB(date);
    setErrors({ ...errors, userDOB: "" }); // Clear error message
    setCompatibilityData(null); // Clear previous API result
  };

  const handlePartnerFullNameChange = (event) => {
    setPartnerFullName(event.target.value);
    setErrors({ ...errors, partnerFullName: "" }); // Clear error message
    setCompatibilityData(null); // Clear previous API result
  };

  const handlePartnerDOBChange = (date) => {
    setPartnerDOB(date);
    setErrors({ ...errors, partnerDOB: "" }); // Clear error message
    setCompatibilityData(null); // Clear previous API result
  };

  useEffect(() => {
    if (userData) {
      setUserFullName(userData.userFullName || "");
      setUserDOB(userData.userDOB ? new Date(userData.userDOB) : null);
    }
  }, [userData]);

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    const newErrors = {};

    if (!userFullName.trim()) {
      newErrors.userFullName = "Please enter Full Name";
    }
    if (!userDOB) {
      newErrors.userDOB = "Please enter Date of Birth";
    }
    if (!partnerFullName.trim()) {
      newErrors.partnerFullName = "Please enter Full Name";
    }
    if (!partnerDOB) {
      newErrors.partnerDOB = "Please enter Date of Birth";
    }

    if (
      !userFullName.trim() ||
      !userDOB ||
      !partnerFullName.trim() ||
      !partnerDOB
    ) {
      setErrors(newErrors);
      return;
    }

    setIsLoading(true);

    // Decrement clicks remaining
    setClicksRemaining((prev) => prev - 1);

    // Start timer only on the first submit
    if (clicksRemaining === 5) {
      startTimer();
    }

    const apiRequest = fetch(
      "https://api.scanandplaytambola.com/patnerCompatibility",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          partner1_Name: userFullName,
          partner2_Name: partnerFullName,
          partner1_dob: userDOB.toISOString().split("T")[0],
          partner2_dob: partnerDOB.toISOString().split("T")[0],
        }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.json();
      })
      .then((responseData) => {
        setCompatibilityData(responseData);
      });

    const timeout = new Promise((resolve) => setTimeout(resolve, 5000));

    await Promise.all([apiRequest, timeout]);

    setIsLoading(false);
  };

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 19, 12, 11, 21, 6, 19, 20, 22, 22, 2];

  return (
    <div className="Partner">
      <Helmet>
        <title>Partner Compatibility</title>
        <meta
          name="description"
          content="Analyze Partner Compatibility to determine the harmony between you and your partner, enhancing your relationship."
        />
      </Helmet>
      <div className="content">
        <br />
        <div className="special1">
          <h1>Partner Compatibility Calculator</h1>
          <div className="input-container">
            <div id="box" className="left-group">
              <h2>Partner 1</h2>
              <div className="input-group">
                <p className="label">Enter your Date of Birth:</p>
                <DatePicker
                  selected={userDOB}
                  onChange={handleUserDOBChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Select date"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {errors.userDOB && (
                  <p className="error-message">{errors.userDOB}</p>
                )}
              </div>
              <div className="input-group">
                <p className="label">Enter your Full Name:</p>
                <input
                  type="text"
                  value={userFullName}
                  onChange={handleUserFullNameChange}
                  placeholder="Type here..."
                />
                {errors.userFullName && (
                  <p className="error-message">{errors.userFullName}</p>
                )}
              </div>
            </div>
            <div id="box" className="right-group">
              <h2>Partner 2</h2>
              <div className="input-group">
                <p className="label">Enter your Date of Birth:</p>
                <DatePicker
                  selected={partnerDOB}
                  onChange={handlePartnerDOBChange}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="Select date"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
                {errors.partnerDOB && (
                  <p className="error-message">{errors.partnerDOB}</p>
                )}
              </div>
              <div className="input-group">
                <p className="label">Enter your Full Name:</p>
                <input
                  type="text"
                  value={partnerFullName}
                  onChange={handlePartnerFullNameChange}
                  placeholder="Type here..."
                />
                {errors.partnerFullName && (
                  <p className="error-message">{errors.partnerFullName}</p>
                )}
              </div>
            </div>
          </div>
          <button
            onClick={handleSubmit}
            disabled={loading || clicksRemaining <= 0}
          >
            Submit
          </button>
        </div>
        <div></div>

        {isLoading && <Loader />}
        {!isLoading && compatibilityData && (
          <div
            className={`left-align-content result-ui ${
              hasContent ? "filled" : ""
            }`}
            id="breadcrumbs"
          >
            <h2 className="h2-center">Result</h2>
            <p>
              <h3>Compatibility Based on Date of Birth:</h3>
              {compatibilityData.compatibility_Based_On_Dob}
            </p>
            <p>
              <h3>Compatibility Based on Name:</h3>
              {compatibilityData.compatibility_Based_On_Name}
            </p>
          </div>
        )}
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>Partner Compatibility Calculation</h2>
          <div>{partnerInstructions.howToCalculate}</div>
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <a href={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ExampleUI texts={exampleTextsCompatibility} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>

      </div>
    </div>
  );
}

export default Partner;
