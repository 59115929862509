import React, { useState, useEffect, useContext } from "react";
import "./KuaNumber.css";
import { API_BASE_URL } from "../components/Constants";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { GlobalStateContext } from "../components/GlobalVariable";
import { kuaNumberInstructions } from "./KuaNumber_How_to";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  KUA_FAQ_QUESTION_1,
  KUA_FAQ_ANSWER_1,
  KUA_FAQ_QUESTION_2,
  KUA_FAQ_ANSWER_2,
  KUA_FAQ_QUESTION_3,
  KUA_FAQ_ANSWER_3,
  KUA_FAQ_QUESTION_4,
  KUA_FAQ_ANSWER_4,
  KUA_FAQ_QUESTION_5,
  KUA_FAQ_ANSWER_5,
  KUA_FAQ_QUESTION_6,
  KUA_FAQ_ANSWER_6,
  KUA_FAQ_QUESTION_7,
  KUA_FAQ_ANSWER_7,
  KUA_FAQ_QUESTION_8,
  KUA_FAQ_ANSWER_8,
  KUA_FAQ_QUESTION_9,
  KUA_FAQ_ANSWER_9,
  KUA_FAQ_QUESTION_10,
  KUA_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsKuaNumber from "./KuaNumber_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: KUA_FAQ_QUESTION_1, answer: KUA_FAQ_ANSWER_1 },
    { question: KUA_FAQ_QUESTION_2, answer: KUA_FAQ_ANSWER_2 },
    { question: KUA_FAQ_QUESTION_3, answer: KUA_FAQ_ANSWER_3 },
    { question: KUA_FAQ_QUESTION_4, answer: KUA_FAQ_ANSWER_4 },
    { question: KUA_FAQ_QUESTION_5, answer: KUA_FAQ_ANSWER_5 },
    { question: KUA_FAQ_QUESTION_6, answer: KUA_FAQ_ANSWER_6 },
    { question: KUA_FAQ_QUESTION_7, answer: KUA_FAQ_ANSWER_7 },
    { question: KUA_FAQ_QUESTION_8, answer: KUA_FAQ_ANSWER_8 },
    { question: KUA_FAQ_QUESTION_9, answer: KUA_FAQ_ANSWER_9 },
    { question: KUA_FAQ_QUESTION_10, answer: KUA_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function KuaNumber({ userData, setUserData }) {
  const url = window.location.href;
  const {
    day,
    setDay,
    month,
    setMonth,
    year,
    setYear,
    rateLimitCount,
    setRateLimitCount,
  } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const mouseTitle = "Find your Kua Number";
  const browserTitle = "Kua Number"; // New constant for browser tab text
  const endpoint = "kuaNumber"; // Example endpoint, adjust as needed
  const [, setError] = useState(null);
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60); // Initial timer countdown seconds
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [gender, setGender] = useState("Male");
  const [kuaNumber, setKuaNumber] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);
  const shareText =
    "Hi , I just found interesting insight about my Kua Number. Find out your with this amazing tool at";

  const [apiData, setApiData] = useState({}); // New state for additional API data

  const clearApiResponse = () => {
    setKuaNumber(null);
    setApiData({});
    setApiError(null);
  };

  const hasContent = kuaNumber !== null || apiError;

  useEffect(() => {
    function updateDaysInMonth(month, year) {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(days);
      if (day > days) {
        alert("Invalid date, re-check and enter again");
        setDay(1);
      }
    }
    updateDaysInMonth(month, year);
  }, [month, year, day]);

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);

    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }

    setLoading(true);
    const startTime = Date.now();

    const formattedDate = `${day.toString().padStart(2, "0")}-${month
      .toString()
      .padStart(2, "0")}-${year}`;
    const apiUrl = `${API_BASE_URL}${endpoint}`;
    let remainingTime = 0;

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dateOfBirth: formattedDate,
          gender: gender.charAt(0).toUpperCase(),
          methodType: "C",
        }),
      });

      const data = await response.json();
      console.log("API Response:", data); // Log the API response for debugging

      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      remainingTime = 5000 - elapsedTime;

      setTimeout(
        () => {
          if (response.ok) {
            setKuaNumber(data.kuaNumber);
            setApiData(data); // Store all API data
            setApiError(null);
            if (typeof setUserData === "function") {
              setUserData({
                ...userData,
                day: day,
                month: month,
                year: year,
                gender: gender,
              });
            }
          } else {
            setKuaNumber(null);
            setApiError(data.kuaNumberError || "Failed to fetch data");
          }
          setLoading(false);
        },
        remainingTime > 0 ? remainingTime : 0
      );
    } catch (error) {
      console.error("Fetch operation failed:", error);
      setTimeout(
        () => {
          setApiError("Failed to connect. Please try again later.");
          setKuaNumber(null);
          setLoading(false);
        },
        remainingTime > 0 ? remainingTime : 0
      );
      // Update loading state and decrement clicks remaining
      setLoading(false);
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 12, 8, 13, 2, 5, 6, 21, 19, 15, 16];

  return (
    <div className="KuaNumber">
      <Helmet>
        <title>Kua Number</title>
        <meta
          name="description"
          content="Discover your Kua number to find your favorable directions and improve your luck and well-being based on Feng Shui principles."
        />
      </Helmet>
      <div className="content">
        <div className="input-and-result-window-kua">
          <div className="special">
            <h1>Kua Number Calculator</h1>

            <p className="label">Select your Gender:</p>
            <select
              value={gender}
              onChange={(e) => {
                setGender(e.target.value);
                clearApiResponse();
              }}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
            <p className="label">Select your Date of Birth:</p>
            <select
              value={day}
              onChange={(e) => {
                setDay(parseInt(e.target.value, 10));
                clearApiResponse();
              }}
            >
              <option value="" disabled selected>
                Day
              </option>
              {Array.from({ length: daysInMonth }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
            <select
              value={month}
              onChange={(e) => {
                setMonth(parseInt(e.target.value, 10));
                clearApiResponse();
              }}
            >
              <option value="" disabled selected>
                Month
              </option>
              {Array.from({ length: 12 }, (_, i) => (
                <option key={i + 1} value={i + 1}>
                  {new Date(0, i + 1, 0).toLocaleString("en", {
                    month: "long",
                  })}
                </option>
              ))}
            </select>
            <select
              value={year}
              onChange={(e) => {
                setYear(parseInt(e.target.value, 10));
                clearApiResponse();
              }}
            >
              <option value="" disabled selected>
                Year
              </option>
              {Array.from({ length: 2025 - 1900 }, (_, i) => (
                <option key={i + 1900} value={i + 1900}>
                  {i + 1900}
                </option>
              ))}
            </select>
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window kua-only">
            <h2>Result</h2>
            {loading && <Loader />}
            <p className="h1-center kua-result-window">
              <b>Kua Number : {kuaNumber}</b>
            </p>
            <br></br>
            <div className="directions-container">
              <div className="directions-box">
                <h3>Your auspicious directions:</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Sheng Chi: </b>
                      </td>
                      <td>{apiData && apiData.direction ? apiData.direction.shengChi : undefined}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Tien Yi:</b>
                      </td>
                      <td>{apiData && apiData.direction ? apiData.direction.tienYee : undefined}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Nien Yen:</b>
                      </td>
                      <td>{apiData && apiData.direction ? apiData.direction.nienYen : undefined}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Fu Wei:</b>
                      </td>
                      <td>{apiData && apiData.direction ? apiData.direction.fooWei : undefined}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="directions-box">
                <h3>Your inauspicious directions:</h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <b>Ho Hai: </b>
                      </td>
                      <td>{apiData && apiData.direction ? apiData.direction.houHai : undefined}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Wu Kwei: </b>
                      </td>
                      <td>{apiData && apiData.direction ? apiData.direction.wuGwei : undefined}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Six Sha: </b>
                      </td>
                      <td>{apiData && apiData.direction ? apiData.direction.liuShar : undefined}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Chueh Ming: </b>
                      </td>
                      <td>{apiData && apiData.direction ? apiData.direction.chuehMeng : undefined}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>How to Calculate Kua Number:</h2>
          <p>{kuaNumberInstructions.howToCalculate}</p>
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <a href={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ExampleUI texts={exampleTextsKuaNumber} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        {/* FAQ Section */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default KuaNumber;
