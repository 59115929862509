import React from "react";
import "./Footer.css";


function Footer() {
  return (
    <footer>
      <nav>
        <ul>
          <li>
            <a href="/about-us">About Us</a>
          </li>
          <li>
            <a href="/contact-us">Contact Us</a>
          </li>
          <li>
            <a href="/terms-and-conditions">T&amp;C</a>
          </li>
          <li>
            <a href="/faq">FAQ</a>
          </li>
        </ul>
      </nav>
      <div className="footer-text">
        <p>© 2024 Elite Numerology. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
