import React, { useState, useEffect, useContext } from "react";
import { GlobalStateContext } from "../components/GlobalVariable";
import "./Pinnacle.css";
import ShareButtons from "../components/ShareButtons";
import { pinnacleInstructions } from "./Pinnacle_How_To";
import DateOfBirthSelector from "../components/DateOfBirthSelector";
import Loader from "../components/Loader";
import MouseFollower from "../components/MouseFollower";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  PINNACLE_FAQ_QUESTION_1,
  PINNACLE_FAQ_ANSWER_1,
  PINNACLE_FAQ_QUESTION_2,
  PINNACLE_FAQ_ANSWER_2,
  PINNACLE_FAQ_QUESTION_3,
  PINNACLE_FAQ_ANSWER_3,
  PINNACLE_FAQ_QUESTION_4,
  PINNACLE_FAQ_ANSWER_4,
  PINNACLE_FAQ_QUESTION_5,
  PINNACLE_FAQ_ANSWER_5,
  PINNACLE_FAQ_QUESTION_6,
  PINNACLE_FAQ_ANSWER_6,
  PINNACLE_FAQ_QUESTION_7,
  PINNACLE_FAQ_ANSWER_7,
  PINNACLE_FAQ_QUESTION_8,
  PINNACLE_FAQ_ANSWER_8,
  PINNACLE_FAQ_QUESTION_9,
  PINNACLE_FAQ_ANSWER_9,
  PINNACLE_FAQ_QUESTION_10,
  PINNACLE_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsPinnacle from "./Pinnacle_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: PINNACLE_FAQ_QUESTION_1, answer: PINNACLE_FAQ_ANSWER_1 },
    { question: PINNACLE_FAQ_QUESTION_2, answer: PINNACLE_FAQ_ANSWER_2 },
    { question: PINNACLE_FAQ_QUESTION_3, answer: PINNACLE_FAQ_ANSWER_3 },
    { question: PINNACLE_FAQ_QUESTION_4, answer: PINNACLE_FAQ_ANSWER_4 },
    { question: PINNACLE_FAQ_QUESTION_5, answer: PINNACLE_FAQ_ANSWER_5 },
    { question: PINNACLE_FAQ_QUESTION_6, answer: PINNACLE_FAQ_ANSWER_6 },
    { question: PINNACLE_FAQ_QUESTION_7, answer: PINNACLE_FAQ_ANSWER_7 },
    { question: PINNACLE_FAQ_QUESTION_8, answer: PINNACLE_FAQ_ANSWER_8 },
    { question: PINNACLE_FAQ_QUESTION_9, answer: PINNACLE_FAQ_ANSWER_9 },
    { question: PINNACLE_FAQ_QUESTION_10, answer: PINNACLE_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function Pinnacle({ userData, setUserData }) {
  const {
    day,
    setDay,
    month,
    setMonth,
    year,
    setYear,
    rateLimitCount,
    setRateLimitCount,
  } = useContext(GlobalStateContext);
  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60);
  const url = window.location.href;
  const mouseTitle = "Find your Pinnacle phase and number";
  const browserTitle = "Pinnacle Number";
  const shareText =
    "Hi, I just learned about the crucial phases of my life through my Pinnacle Numbers. Find out your at";
  const [daysInMonth, setDaysInMonth] = useState(31);
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const hasContent = apiResponse !== null;

  useEffect(() => {
    function updateDaysInMonth(month, year) {
      const days = new Date(year, month, 0).getDate();
      setDaysInMonth(days);
      if (day > days) {
        alert("Invalid date, re-check and enter again");
        setDay(1);
      }
    }
    updateDaysInMonth(month, year);
  }, [month, year, day]);

  const handleDayChange = (event) => {
    setDay(parseInt(event.target.value, 10));
    setApiResponse(null); // Clear previous API result
  };

  const handleMonthChange = (event) => {
    setMonth(parseInt(event.target.value, 10));
    setApiResponse(null); // Clear previous API result
  };

  const handleYearChange = (event) => {
    setYear(parseInt(event.target.value, 10));
    setApiResponse(null); // Clear previous API result
  };

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    // Clear previous errors
    setError(null);
    setApiResponse(null); // Clear previous API result
    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }
    const dateOfBirth = `${day}-${month}-${year}`;
    setUserData({
      ...userData,
      day: day,
      month: month,
      year: year,
    });

    setLoading(true);
    setError(null);

    const minimumLoaderTime = 5000; // 5 seconds
    const startTime = Date.now(); // Record the start time

    try {
      const response = await fetch(
        "https://api.scanandplaytambola.com/pinacleNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dateOfBirth: dateOfBirth,
          }),
        }
      );

      const data = await response.json();
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const remainingTime = minimumLoaderTime - elapsedTime;

      if (remainingTime > 0) {
        setTimeout(() => {
          setApiResponse(data);
          setLoading(false);
        }, remainingTime);
      } else {
        setApiResponse(data);
        setLoading(false);
      }
    } catch (error) {
      setError("Failed to fetch the data. Please try again.");
      setLoading(false);
    } finally {
      // Decrement clicks remaining
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  useEffect(() => {
    document.title = browserTitle; // Set document title on component mount
  }, []); // Empty dependency array to run only once

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 12, 18, 6, 14, 2, 11, 10, 7, 19, 19];

  return (
    <div className="Pinnacle">
      <Helmet>
        <title>Pinnacle Number and Cycles</title>
        <meta
          name="description"
          content="Discover your Pinnacle Numbers to understand the key phases of your life, highlighting opportunities and challenges."
        />
      </Helmet>
      <div className="content">
        <br></br>
        <div className="input-and-result-window">
          <div className="special">
            <h1>Pinnacle Cycle Calculator</h1>
            <div>
              <DateOfBirthSelector
                day={day}
                month={month}
                year={year}
                daysInMonth={daysInMonth}
                handleDayChange={handleDayChange}
                handleMonthChange={handleMonthChange}
                handleYearChange={handleYearChange}
              />
            </div>
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {loading && <Loader />}
            <p>
              First Pinnacle Age :{" "}
              <strong>
                {!loading && apiResponse ? apiResponse.firstPinacleAge : ""}
              </strong>
            </p>
            <p>
              Second Pinnacle Age :{" "}
              <strong>
                {!loading && apiResponse ? apiResponse.secondPinacleAge : ""}
              </strong>
            </p>
            <p>
              Third Pinnacle Age :{" "}
              <strong>
                {!loading && apiResponse ? apiResponse.thirdPinacleAge : ""}
              </strong>
            </p>
            <p>
              Fourth Pinnacle Age :{" "}
              <strong>
                {!loading && apiResponse ? apiResponse.fourthPinacleAge : ""}
              </strong>
            </p>
            <p>
              First Pinnacle Number :{" "}
              <strong>
                {!loading && apiResponse ? apiResponse.firstPinacleNumber : ""}
              </strong>
            </p>
            <p>
              Second Pinnacle Number :{" "}
              <strong>
                {!loading && apiResponse ? apiResponse.firstPinacleNumber : ""}
              </strong>
            </p>
            <p>
              Third Pinnacle Number :{" "}
              <strong>
                {!loading && apiResponse ? apiResponse.firstPinacleNumber : ""}
              </strong>
            </p>
            <p>
              Fourth Pinnacle Number :{" "}
              <strong>
                {!loading && apiResponse ? apiResponse.firstPinacleNumber : ""}
              </strong>
            </p>
          </div>
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
        >
          <p className="share-line" style={{ margin: 0 }}>
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>Pinnacle Number Calculation</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: pinnacleInstructions.howToCalculate,
            }}
          />
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <a href={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ExampleUI texts={exampleTextsPinnacle} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />{" "}
        {/* Pass mouseTitle as a prop */}
        {/* FAQ Section */}
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default Pinnacle;
