import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom"; // Import Link from React
import logo1 from "../assets/logo1.png";

function Header() {
  const [isLoginDropdownOpen, setIsLoginDropdownOpen] = useState(false);
  const [isLogin2DropdownOpen, setIsLogin2DropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNumerologyOpen, setIsNumerologyOpen] = useState(false);
  const [isCompatibilityOpen, setIsCompatibilityOpen] = useState(false);
  const hoverTimeout = useRef(null);
  const location = useLocation();
  const isFooterPage =
    location.pathname === "/" ||
    location.pathname === "/about-us" ||
    location.pathname === "/contact-us" ||
    location.pathname === "/terms-and-conditions" ||
    location.pathname === "/faq";

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    // Event listener to close dropdowns when clicking outside
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown")) {
        setIsNumerologyOpen(false);
        setIsCompatibilityOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside both dropdowns
      if (!event.target.closest(".dropdown-login")) {
        setIsLoginDropdownOpen(false);
        setIsLogin2DropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleLoginDropdown = (event) => {
    event.stopPropagation();
    setIsLoginDropdownOpen((prev) => {
      if (!prev) {
        setIsLogin2DropdownOpen(false);
      }
      return !prev;
    });
  };

  const toggleLogin2Dropdown = (event) => {
    event.stopPropagation();
    setIsLogin2DropdownOpen((prev) => {
      if (!prev) {
        setIsLoginDropdownOpen(false);
      }
      return !prev;
    });
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleNumerologyDropdown = (event) => {
    event.stopPropagation();
    setIsNumerologyOpen(!isNumerologyOpen);
    setIsCompatibilityOpen(false);
  };

  const toggleCompatibilityDropdown = (event) => {
    event.stopPropagation();
    setIsCompatibilityOpen(!isCompatibilityOpen);
    setIsNumerologyOpen(false);
  };

  const closeDropdownMenus = () => {
    setIsNumerologyOpen(false);
    setIsCompatibilityOpen(false);
    setIsMobileMenuOpen(false);
    setIsLoginDropdownOpen(false);
    setIsLogin2DropdownOpen(false);
  };

  const handleMouseEnter = (menuSetter, otherMenuSetter) => {
    clearTimeout(hoverTimeout.current);
    menuSetter(true);
    otherMenuSetter(false);
  };

  const handleMouseLeave = (menuSetter) => {
    hoverTimeout.current = setTimeout(() => {
      menuSetter(false);
    }, 1000); // 1 second delay
  };

  return (
    <header className="header">
      <div className={`header-content ${isMobileMenuOpen ? "open" : ""}`}>
        <div className="header-logo-and-h1">
          <a href="/" className="header-nav-logo">
            <img className="header-logo-image" src={logo1} alt="Logo" />
          </a>
        </div>

        <nav className={`header-nav-center ${isMobileMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a href ="/" onClick={closeDropdownMenus}>
                Home
              </a>
            </li>
            <li
              className={`dropdown ${isNumerologyOpen ? "open" : ""}`}
              onMouseEnter={() =>
                handleMouseEnter(setIsNumerologyOpen, setIsCompatibilityOpen)
              }
              onMouseLeave={() => handleMouseLeave(setIsNumerologyOpen)}
            >
              <a href="#" onClick={toggleNumerologyDropdown}>
                Numerology
              </a>
              <div className="dropdown-content">
                {/* List of Links */}
                <a href="/balance-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Balance Number
                </a>
                <a href="/is-your-bank-account-number-lucky"
                  onClick={closeDropdownMenus}
                >
                  Bank A/C Number
                </a>

                <a href="/personal-fast-day-calculator-numerology"
                  onClick={closeDropdownMenus}
                >
                  Best Day to Fast
                </a>
                <a href="/lucky-business-name-calculator"
                  onClick={closeDropdownMenus}
                >
                  Business Name
                </a>
                <a href="/daily-numerological-chart"
                  onClick={closeDropdownMenus}
                >
                  Daily Biorhythm Chart
                </a>
                <a href="/destiny-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Destiny Number
                </a>
                <a href="/hidden-passion-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Hidden Passion Number
                </a>
                <a href="/is-your-house-number-lucky-with-remedies"
                  onClick={closeDropdownMenus}
                >
                  House Number
                </a>
                <a href="/jersey-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Jersey Number
                </a>
                <a href="/what-are-karmic-debt-numbers-in-numerology"
                  onClick={closeDropdownMenus}
                >
                  Karmic Debt Number
                </a>
                <a href="/kua-number-calculator" onClick={closeDropdownMenus}>
                  Kua Number
                </a>
                <a href="/life-path-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Life Path Number
                </a>
                <a href="/create-your-lo-shu-grid-missing-number-remedies"
                  onClick={closeDropdownMenus}
                >
                  Lo Shu Grid
                </a>

                <a href="/numerology-maturity-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Maturity Number
                </a>
                <a href="/is-your-mobile-number-lucky"
                  onClick={closeDropdownMenus}
                >
                  Mobile Number
                </a>
                <a href="/personalized-monthly-biorhythm-chart"
                  onClick={closeDropdownMenus}
                >
                  Monthly Biorhythm Chart
                </a>
                <a href="/name-number-calculator" onClick={closeDropdownMenus}>
                  Name Number
                </a>
                <a href="/numerology-personal-year-calculator"
                  onClick={closeDropdownMenus}
                >
                  Personal Year Number
                </a>
                <a href="/personality-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Personality Number
                </a>
                <a href="/pinnacle-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Pinnacle Cycle
                </a>
                <a href="/soul-urge-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Soul Urge Number
                </a>
                <a href="/subconscious-self-number-calculator"
                  onClick={closeDropdownMenus}
                >
                  Subconscious Self Number
                </a>
                <a href="/is-your-vehicle-number-lucky-with-remedies"
                  onClick={closeDropdownMenus}
                >
                  Vehicle Number
                </a>
              </div>
            </li>
            <li
              className={`dropdown ${isCompatibilityOpen ? "open" : ""}`}
              onMouseEnter={() =>
                handleMouseEnter(setIsCompatibilityOpen, setIsNumerologyOpen)
              }
              onMouseLeave={() => handleMouseLeave(setIsCompatibilityOpen)}
            >
              <a href="#" onClick={toggleCompatibilityDropdown}>
                Compatibility
              </a>
              <div className="dropdown-content">
                <a href="/numerology-partner-compatibility"
                  onClick={closeDropdownMenus}
                >
                  Partner Compatibility
                </a>
                <a href="/numerology-kua-compatibility"
                  onClick={closeDropdownMenus}
                >
                  Kua Number Compatibility
                </a>
              </div>
            </li>
          </ul>
        </nav>

        <nav className={`header-nav-right ${isMobileMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <a
                href="https://api.whatsapp.com/send?phone=9910335637&text=I%20want%20to%20book%20a%20numerology%20consultation"
                className="chat-now"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chat Now
              </a>
            </li>

            <li
              className={`dropdown-login ${isLoginDropdownOpen ? "open" : ""}`}
              onClick={(e) => e.stopPropagation()}
            >
              <a
                className="chat-now signup-login"
                onClick={toggleLoginDropdown}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="#000000"
                  viewBox="0 0 256 256"
                >
                  <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM74.08,197.5a64,64,0,0,1,107.84,0,87.83,87.83,0,0,1-107.84,0ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120Zm97.76,66.41a79.66,79.66,0,0,0-36.06-28.75,48,48,0,1,0-59.4,0,79.66,79.66,0,0,0-36.06,28.75,88,88,0,1,1,131.52,0Z"></path>
                </svg>
                (1)
              </a>
              {isLoginDropdownOpen && (
                <div className="dropdown-login-content">
                  <Link
                    to="/my-profile"
                    className="chat-now signup-login"
                    onClick={closeDropdownMenus}
                  >
                    My Profile
                  </Link>
                  <Link
                    to="/credit-balance"
                    className="chat-now signup-login"
                    onClick={closeDropdownMenus}
                  >
                    Credit Balance
                  </Link>
                  <Link
                    to="/services-purchased"
                    className="chat-now signup-login"
                    onClick={closeDropdownMenus}
                  >
                    Service History
                  </Link>
                  <Link
                    to="/services-page"
                    className="chat-now signup-login"
                    onClick={closeDropdownMenus}
                  >
                    Services
                  </Link>
                  <Link
                    to="/logout"
                    className="chat-now signup-login"
                    onClick={closeDropdownMenus}
                  >
                    Log Out
                  </Link>
                </div>
              )}
            </li>

            <li
              className={`dropdown-login ${isLogin2DropdownOpen ? "open" : ""}`}
              onClick={(e) => e.stopPropagation()}
            >
              <a
                className="chat-now signup-login"
                onClick={toggleLogin2Dropdown}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill="#000000"
                  viewBox="0 0 256 256"
                >
                  <path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM74.08,197.5a64,64,0,0,1,107.84,0,87.83,87.83,0,0,1-107.84,0ZM96,120a32,32,0,1,1,32,32A32,32,0,0,1,96,120Zm97.76,66.41a79.66,79.66,0,0,0-36.06-28.75,48,48,0,1,0-59.4,0,79.66,79.66,0,0,0-36.06,28.75,88,88,0,1,1,131.52,0Z"></path>
                </svg>
                (2)
              </a>
              {isLogin2DropdownOpen && (
                <div className="dropdown-login-content">
                  <Link
                    to="/signup"
                    className="chat-now signup-login"
                    onClick={closeDropdownMenus}
                  >
                    Sign Up
                  </Link>
                  <Link
                    to="/login"
                    className="chat-now signup-login"
                    onClick={closeDropdownMenus}
                  >
                    Login
                  </Link>
                </div>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
