import React, { useState, useEffect, useContext } from "react";
import pythagoreanChart from "../assets/Pythagorean-1.png";
import { balanceInstructions } from "./Balance_How_To";
import "./Balance.css";
import ShareButtons from "../components/ShareButtons";
import Loader from "../components/Loader";
import { Helmet } from "react-helmet";
import MouseFollower from "../components/MouseFollower";
import { GlobalStateContext } from "../components/GlobalVariable";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { all_images } from "../components/Related-Tools";
import {
  BALANCE_FAQ_QUESTION_1,
  BALANCE_FAQ_ANSWER_1,
  BALANCE_FAQ_QUESTION_2,
  BALANCE_FAQ_ANSWER_2,
  BALANCE_FAQ_QUESTION_3,
  BALANCE_FAQ_ANSWER_3,
  BALANCE_FAQ_QUESTION_4,
  BALANCE_FAQ_ANSWER_4,
  BALANCE_FAQ_QUESTION_5,
  BALANCE_FAQ_ANSWER_5,
  BALANCE_FAQ_QUESTION_6,
  BALANCE_FAQ_ANSWER_6,
  BALANCE_FAQ_QUESTION_7,
  BALANCE_FAQ_ANSWER_7,
  BALANCE_FAQ_QUESTION_8,
  BALANCE_FAQ_ANSWER_8,
  BALANCE_FAQ_QUESTION_9,
  BALANCE_FAQ_ANSWER_9,
  BALANCE_FAQ_QUESTION_10,
  BALANCE_FAQ_ANSWER_10,
} from "../components/Constants";
import exampleTextsBalance from "./Balance_Example_Text";
import ExampleUI from "../components/ExampleUI";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const rawFaqs = [
    { question: BALANCE_FAQ_QUESTION_1, answer: BALANCE_FAQ_ANSWER_1 },
    { question: BALANCE_FAQ_QUESTION_2, answer: BALANCE_FAQ_ANSWER_2 },
    { question: BALANCE_FAQ_QUESTION_3, answer: BALANCE_FAQ_ANSWER_3 },
    { question: BALANCE_FAQ_QUESTION_4, answer: BALANCE_FAQ_ANSWER_4 },
    { question: BALANCE_FAQ_QUESTION_5, answer: BALANCE_FAQ_ANSWER_5 },
    { question: BALANCE_FAQ_QUESTION_6, answer: BALANCE_FAQ_ANSWER_6 },
    { question: BALANCE_FAQ_QUESTION_7, answer: BALANCE_FAQ_ANSWER_7 },
    { question: BALANCE_FAQ_QUESTION_8, answer: BALANCE_FAQ_ANSWER_8 },
    { question: BALANCE_FAQ_QUESTION_9, answer: BALANCE_FAQ_ANSWER_9 },
    { question: BALANCE_FAQ_QUESTION_10, answer: BALANCE_FAQ_ANSWER_10 },
  ];

  const faqs = rawFaqs.filter((faq) => faq.question);

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <span className="arrow">{activeIndex === index ? "-" : "+"}</span>{" "}
            {faq.question}
          </div>
          {activeIndex === index && (
            <div className="faq-answer" onClick={() => toggleFAQ(index)}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function Balance({ userData, setUserData }) {
  const { rateLimitCount, setRateLimitCount, fullName, setFullName } =
    useContext(GlobalStateContext);

  const updateGlobalVariable = () => {
    //e.preventDefault();
    if (rateLimitCount > 0) {
      setRateLimitCount(rateLimitCount - 1);
    }
  };

  const url = window.location.href;
  const mouseTitle = "Find your Balance Number";
  const browserTitle = "Balance Number";
  const shareText =
    "Hi, I just found out what I need to do during tough times. Check yours at";
  const [apiResponse, setApiResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clicksRemaining, setClicksRemaining] = useState(5);
  const [, setTimerVisible] = useState(false);
  const [, setTimerSeconds] = useState(60); // Initial timer countdown seconds
  const hasContent = apiResponse !== null;

  const handleFullNameChange = (event) => {
    setFullName(event.target.value); // Use setFullName from global context
    setApiResponse(null); // Clear previous API result
  };

  useEffect(() => {
    document.title = browserTitle;
  }, []);

  const startTimer = () => {
    setTimerVisible(true);

    // Timer to update seconds every second until the next minute starts
    const timerInterval = setInterval(() => {
      setTimerSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timerInterval); // Clear interval when timer reaches 0
          setTimerSeconds(60); // Restart timer from 60 seconds
          setClicksRemaining(5); // Refresh clicks remaining to 5
          startTimer(); // Restart the timer
        }
        return prevSeconds - 1;
      });
    }, 1000);
  };

  const handleSubmit = async () => {
    updateGlobalVariable();
    setError(null);

    setApiResponse(null);

    // Validate full name
    if (!fullName) {
      setError("Please enter your Full Name");
      return;
    }

    // Prevent submission if clicks remaining are exhausted
    if (clicksRemaining <= 0) {
      setError(
        "You have reached the maximum number of submissions. Please wait for the timer to reset."
      );
      return;
    }

    // Update user data
    setUserData({
      ...userData,
      fullName: fullName,
    });

    // Start loading state
    setLoading(true);

    try {
      // Perform API call
      const startTime = Date.now();
      const response = await fetch(
        "https://api.scanandplaytambola.com/balanceNumber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: fullName,
          }),
        }
      );

      // Parse response data
      const data = await response.json();

      // Simulate minimum loading time
      const endTime = Date.now();
      const elapsedTime = endTime - startTime;
      const minimumLoadingTime = 5000; // Minimum loading time in milliseconds

      if (elapsedTime < minimumLoadingTime) {
        await new Promise((resolve) =>
          setTimeout(resolve, minimumLoadingTime - elapsedTime)
        );
      }

      // Update API response state
      setApiResponse(data);
    } catch (error) {
      // Handle fetch errors
      setError("Failed to fetch the data. Please try again.");
    } finally {
      // Update loading state and decrement clicks remaining
      setLoading(false);
      setClicksRemaining((prev) => prev - 1);

      // Start timer only on the first submit
      if (clicksRemaining === 5) {
        startTimer();
      }
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true, // Optionally enable/disable arrows if needed
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
      {
        breakpoint: 485,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const imageIndexes = [0, 1, 13, 22, 5, 10, 7, 23, 20, 15, 21, 12];

  return (
    <div className="Balance">
      <Helmet>
        <title>Balance Number</title>
        <meta
          name="description"
          content="Find your Balance Number to manage stress and maintain equilibrium during difficult times, based on your numerology profile."
        />
      </Helmet>
      <div className="content">
        <br></br>
        <div className="input-and-result-window">
          <div className="special">
            <h1>What is your Balance number?</h1>
            <div>
              <p className="label">Enter your Full Name:</p>
              <input
                type="text"
                value={fullName}
                onChange={handleFullNameChange}
                placeholder="Type here..."
              />
            </div>
            {error && <p className="error-message">{error}</p>}
            <button
              onClick={handleSubmit}
              disabled={loading || clicksRemaining <= 0}
            >
              Submit
            </button>
          </div>
          <div className="special result-window">
            <h2>Result</h2>
            {loading && <Loader />}
            {/* {loading && <Loader />} */}
            <p>
              Balance Number:{" "}
              <b>
                {apiResponse ? apiResponse.balanceNumber.balance_Number : ""}
                {/* apiResponse ? apiResponse.balanceNumber.balance_Number : "" */}
              </b>
            </p>

            {apiResponse && (
              <>
                <h4>Interpretation:</h4>
                <p>{apiResponse.balanceNumber.meaning}</p>
                {/* <p>{apiResponse.balanceNumber.meaning}</p> */}
              </>
            )}
          </div>
        </div>
        <div
          className="left-align-content"
          id="breadcrumbs"
          style={{ display: "flex", alignItems: "center" }}
          // style={{display: "flex", alignItems: "center"}}
        >
          <p className="share-line" style={{ margin: 0 }}>
            {/* <p className="share-line" style={{margin: 0}}>
            Please feel free to share with your Friends and Family :
            </p> */}
            Please feel free to share with your Friends and Family :
          </p>
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <div className="left-align-content">
          <h2>Balance Number Calculation</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: balanceInstructions.howToCalculate,
            }}
          />
          {/* <div
          dangerouslySetInnerHTML={{
          __html: balanceInstructions.howToCalculate,
          }} */}
          <img
            src={pythagoreanChart}
            alt="Pythagorean Numerology Chart"
            className="center-image-pythagorean"
            width="800"
          />
          {/* <img
          src={pythagoreanChart}
          alt="Pythagorean Numerology Chart"
          classname="center-image-pythagorean"
          width="800"
          /> */}
        </div>
        <div className="related-tools-div">
          <h1 className="h1-center">Our Other Tools</h1>
          <div className="carousel-container">
            <Slider {...settings}>
              {imageIndexes.map((index) => (
                <div key={index}>
                  <a href={all_images[index].link}>
                    <img
                      src={all_images[index].imageSrc}
                      alt={all_images[index].alt}
                      className="carousel-image"
                    />
                  </a>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <ExampleUI texts={exampleTextsBalance} />
        <div className="sharebuttons">
          <ShareButtons url={url} shareText={shareText} />
        </div>
        <MouseFollower mouseTitle={mouseTitle} />
        <div className="faq-section">
          <h2 className="faq-heading">Frequently Asked Questions</h2>
          <Faq />
        </div>
      </div>
    </div>
  );
}

export default Balance;
